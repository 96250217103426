import React from 'react'
// @ts-ignore
import TouchCarousel, {clamp} from 'react-touch-carousel'
// @ts-ignore
import touchWithMouseHOC from 'react-touch-carousel/lib/touchWithMouseHOC'
import cx from 'classnames'
import ReactStars from 'react-stars'
import {StaticImage} from 'gatsby-plugin-image'
import {IS_BROWSER} from '../utils/settings'

const OPTIONS = {passive: false}

class NonPassiveTouchTarget extends React.Component {
  componentDidMount() {
    // @ts-ignore
    this.node.addEventListener('touchmove', this.props.onTouchMove, OPTIONS)
  }

  // @ts-ignore
  componentDidUpdate(prevProps) {
    // @ts-ignore
    if (prevProps.onTouchMove !== this.props.onTouchMove) {
      // @ts-ignore
      this.node.removeEventListener('touchmove', prevProps.onTouchMove, OPTIONS)
      // @ts-ignore
      this.node.addEventListener('touchmove', this.props.onTouchMove, OPTIONS)
    }
  }

  componentWillUnmount() {
    // @ts-ignore
    this.node.removeEventListener('touchmove', this.props.onTouchMove, OPTIONS)
  }

  // @ts-ignore
  ref = (node) => {
    // @ts-ignore
    this.node = node
  }

  render() {
    // @ts-ignore
    const {component: Component, onTouchMove, ...rest} = this.props
    return (
      <Component
        ref={this.ref}
        {...rest}
      />
    )
  }
}

// @ts-ignore
NonPassiveTouchTarget.defaultProps = {
  component: 'div',
  onTouchMove() {
  },
}

const data = [
  {
    rating: 5,
    title: 'Тазагул',
    image: (
      <StaticImage style={{
        margin: '10px auto',
        borderRadius: 50,
        border: '1px solid white',
        objectFit: 'cover',
        width: '100px',
        height: '100px',
      }} alt={'Тазагул'} src={'../assets/pictures/photo1646989061.jpeg'}/>
    ),
    background: '#0072bb',
    //text_ky: `Бардык программалоого кызыккан, өзүн ар тараптан өнүктүрүүнү каалагандарга Besoft Teach академиясын сунуштайм! Академияда сабактар co-working форматында өтүлөт, бардыгы жөнөкөй тил менен, бардыгы жеңил жол менен түшүндүрүлөт, Менторлор 4-5 жылдык реалдуу проектттерде тажрыйбасы бар, мыктылар иштейт. Besoft Teach санына эмес сапатына иштейт чындыгында.`
    text: `Академию Besoft Teach рекомендую всем тем, кто интересуется программированием и хочет развиваться во всех сферах. Занятии в академии проходят в формате coworking, все темы объясняются простым языком, легко. Менторы имеют опыта больше 5 лет в реальных проектах, работают лучшие программисты. Besoft Teach работает над качеством, а не над количеством.`,
  },
  {
    rating: 5,
    title: 'Али',
    image: (
      <StaticImage style={{
        margin: '10px auto',
        borderRadius: 50,
        border: '1px solid white',
        objectFit: 'cover',
        width: '100px',
        height: '100px',
      }} alt={'Али'} src={'../assets/no-avatar.png'}/>
    ),
    background: '#ff4c3b',
    //text_ky: `10 из 10 огромное приймущество это то что нас учат профи своего дела и если понадобиться поддерживают отстающих.`
    text: `10 из 10 огромное приймущество это то что нас учат профи своего дела и если понадобиться поддерживают отстающих.`,
  },
  {
    rating: 4.5,
    title: 'Уланбек',
    image: (
      <StaticImage style={{
        margin: '10px auto',
        borderRadius: 50,
        border: '1px solid white',
        objectFit: 'cover',
        width: '100px',
        height: '100px',
      }} alt={'Уланбек'} src={'../assets/no-avatar.png'}/>
    ),
    background: '#ffca18',
    //text_ky: `Мен Besoft Teach'ке келгенип код эмне экенин, IT сфера кандай экенин билдим жана башка көп коддорду жазууну үйрөндүм.`
    text: `Когда я пришел в Besoft Teach, я узнал, что такое код, что такое IT-сфера, и научился писать много других кодов.`,
  },
  {
    rating: 5,
    title: 'Фаридун',
    image: (
      <StaticImage style={{
        margin: '10px auto',
        borderRadius: 50,
        border: '1px solid white',
        objectFit: 'cover',
        width: '100px',
        height: '100px',
      }} alt={'Фаридун'} src={'../assets/pictures/avatar1538818715-0.jpg'}/>
    ),
    background: '#44c1c1',
    //text_ky: `Besoft Teach академиясынын жалпы жамаатына ыраазычылык билдиргим келди 😊, Себеби бул жерден көптү үйрөндүм, мурун программалоо жөнүндө кыялдансам, азыр кыялдарым ишке ашыруу менен алекмин, Менторлордун жардамында IT дүйнөсүндө соонун картага ээ болдум 👍. Жашоодо максатка карай конкреттүү картаң болсо, ошончолук натыйжалуу болот деп ойлойм. Келгиле, окугула, өнүккүлө!`
    text: `Хочу поблагодарить всю команду Академии Besoft Teach, ведь здесь я многому научился, раньше мечтал о программировании, сейчас занят реализацией своей мечты, с помощью менторов получил красивую карту мира IT.  Я думаю, чем эффективнее ваша цель в жизни, тем эффективнее она будет. Приходи, запишись, развивайся!`,
  },
  {
    rating: 5,
    title: 'Султанбек',
    image: (
      <StaticImage style={{
        margin: '10px auto',
        borderRadius: 50,
        border: '1px solid white',
        objectFit: 'cover',
        width: '100px',
        height: '100px',
      }} alt={'Султанбек'} src={'../assets/no-avatar.png'}/>
    ),
    background: '#29c53c',
    //text_ky: `Баарына сунуштайм. Озгочо атмосфера. Коптогон достор. Жылуу жылуу 😋😋 сезимдер.`
    text: `В Академии вы почувствуете особую атмосферу, найдёте много друзей программистов и конечно получите тёплые чувства.`,
  },
]

const enableLoop = true
const enableAutoplay = true

const cardSize = 300
const cardPadCount = enableLoop ? 3 : 0
const carouselWidth = IS_BROWSER ? clamp(window.innerWidth, 0, 960) : 0

// @ts-ignore
function CarouselContainer(props) {
  const {cursor, carouselState: {active, dragging}, ...rest} = props
  let current = -Math.round(cursor) % data.length
  while (current < 0) {
    current += data.length
  }
  // Put current card at center
  const translateX = (cursor - cardPadCount) * cardSize + (carouselWidth - cardSize) / 2
  return (
    // @ts-ignore
    <NonPassiveTouchTarget
      className={cx(
        'carousel-container',
        {
          'is-active': active,
          'is-dragging': dragging,
        },
      )}
    >
      <NonPassiveTouchTarget
        className="carousel-track"
        style={{transform: `translate3d(${translateX}px, 0, 0)`}}
        {...rest}
      />
      {/*<div className='carousel-pagination-wrapper'>*/}
      {/*  <ol className='carousel-pagination'>*/}
      {/*    {data.map((_, index) => (*/}
      {/*      <li*/}
      {/*        key={index}*/}
      {/*        className={current === index ? 'current' : ''}*/}
      {/*      />*/}
      {/*    ))}*/}
      {/*  </ol>*/}
      {/*</div>*/}
    </NonPassiveTouchTarget>
  )
}

const Container = touchWithMouseHOC(CarouselContainer)

class App extends React.Component {
  renderCard(index: number, modIndex: number) {
    const item = data[modIndex]
    return (
      <div
        key={index}
        className={'carousel-card'}
        onClick={() => console.log(`clicked card ${1 + modIndex}`)}
      >
        <div
          className={'carousel-card-inner'}
          style={{backgroundColor: item.background}}
        >
          {item.image}
          <div className="carousel-title">{item.title}</div>
          <div className={'d-flex justify-content-center align-items-center'}>
            <ReactStars
              count={5}
              value={item.rating}
              size={24}
              className={'review-star'}
              edit={false}
              color1={'white'}
              color2={'#007e38'}
            />
          </div>
          <div className="carousel-text">{item.text}</div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <>
        <TouchCarousel
          component={Container}
          cardSize={cardSize}
          cardCount={data.length}
          cardPadCount={cardPadCount}
          loop={enableLoop}
          autoplay={enableAutoplay ? 2e3 : false}
          renderCard={this.renderCard}
          onRest={(index: number) => console.log(`rest at index ${index}`)}
          onDragStart={() => console.log('dragStart')}
          onDragEnd={() => console.log('dragEnd')}
          onDragCancel={() => console.log('dragCancel')}
        />
      </>
    )
  }
}

export default App
