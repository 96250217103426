import * as React from "react"
import type { HeadFC } from "gatsby"
import {useState} from 'react'
import {Button} from 'react-bootstrap'
import {StaticImage} from 'gatsby-plugin-image'

import HeaderComponent from '../components/HeaderComponent'
import WhoAreWeComponent from '../components/WhoAreWeComponent'
import FooterComponent from '../components/FooterComponent'
import JoinUsComponent from '../components/JoinUsComponent'
import CallbackComponent from '../components/CallbackComponent'
import CoursesComponent from '../components/CoursesComponent'
import ReviewsComponent from '../components/ReviewsComponent'
import {APP_COLOR} from '../utils/settings'

import '../index.scss'

const IndexPage = () => {
  const [callbackShow, setCallbackShow] = useState(false);

  return (
    <>
      <CallbackComponent
        show={callbackShow}
        handleClose={() => setCallbackShow(false)}
      />

      <HeaderComponent setCallbackShow={setCallbackShow} />

      <div className="bg-shapes overflow-hidden">
        <WhoAreWeComponent />

        <div className="container my-5">
          <div className="row justify-content-center">
            <div className="col-12 col-md-11 col-lg-9">
              <div className="row">
                <div className="col-12 col-md-4 d-flex justify-content-center align-items-center">
                  <StaticImage
                    style={{ maxWidth: "300px", minWidth: "100px" }}
                    src={"../assets/4136918.jpg"}
                    alt={""}
                  />
                </div>
                <div className="col-12 col-md-8 d-flex justify-content-center flex-column my-2 my-md-0 align-items-center align-items-md-start">
                  <h2
                    className="text-center text-md-start"
                    style={{ color: APP_COLOR }}
                  >
                    Программистом может стать каждый.
                  </h2>
                  <p className={"font-roboto"}>
                    Мы обучаем IT и digital профессиям. Поможем каждому создать
                    свою историю успеха!
                  </p>
                </div>

                <div className="col-12 col-md-8 d-flex justify-content-center flex-column my-2 my-md-0 align-items-center align-items-md-start">
                  <h2
                    className="text-center text-md-start"
                    style={{ color: "#49B786" }}
                  >
                    Постройте успешную IT карьеру с Besoft.
                  </h2>
                  <p className={"font-roboto"}>
                    Мы готовим полноценному трудоустройству в IT: обучаем
                    английскому и навыкам коммуникации, помогаем подготовиться к
                    собеседованиям в IT компании.
                  </p>
                </div>
                <div className="col-12 col-md-4 d-flex justify-content-center align-items-center">
                  <StaticImage
                    style={{ maxWidth: "300px", minWidth: "100px" }}
                    alt={""}
                    src={"../assets/4401280.jpg"}
                  />
                </div>

                <div className="col-12 col-md-4 d-flex justify-content-center align-items-center">
                  <StaticImage
                    style={{ maxWidth: "300px", minWidth: "100px" }}
                    alt={""}
                    src={"../assets/6502423.jpg"}
                  />
                </div>
                <div className="col-12 col-md-8 d-flex justify-content-center flex-column my-2 my-md-0 align-items-center align-items-md-start">
                  <h2
                    className="text-center text-md-start"
                    style={{ color: "#49B786" }}
                  >
                    Научись программировать с нуля до уровня профи.
                  </h2>
                  <p className={"font-roboto"}>
                    Мы верим, что лежит внутри вас нераскрытый талант. И мы
                    здесь, чтобы помочь вам раскрыть его.
                  </p>
                </div>
              </div>

              <CoursesComponent setCallbackShow={setCallbackShow} />

              <div className={"mt-5"}>
                <h1 className="text-center mt-5 mb-3">Отзывы студентов</h1>
                <ReviewsComponent />
              </div>

              <p className={"my-5 text-muted fs-5 text-center"}>
                Академия программирования Besoft Teach поможет вам овладеть
                навыками программирования ускорено и качественно
              </p>
              <div className={"d-flex justify-content-center"}>
                <Button
                  variant={"outline-primary rounded-pill"}
                  size={"lg"}
                  onClick={() => setCallbackShow(true)}
                  style={{ width: "300px" }}
                >
                  Оставить заявку
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-5">
          <JoinUsComponent />
        </div>

        <FooterComponent />
      </div>
    </>
  );
}

export default IndexPage

export const Head: HeadFC = () => <title>Академия Besoft Teach</title>
