import { Button, Modal, Form, Spinner } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { TELEGRAM_BOT_TOKEN } from "../utils/settings";
import { StaticImage } from "gatsby-plugin-image";

interface CallbackComponentProps {
  show: boolean;
  handleClose: () => void;
}

function CallbackComponent(props: CallbackComponentProps) {
  const { show, handleClose } = props;

  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [busy, setBusy] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (!show) {
      setTimeout(() => {
        setSuccess(false);
      }, 1000);
    }
  }, [show]);

  const submit = () => {
    if (busy) {
      return;
    }
    setBusy(true);
    axios
      .post(`https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`, {
        chat_id: "-1002104746431",
        parse_mode: "HTML",
        text: `Полное имя: <strong>${fullName}</strong>\nТелефон номер: <strong><a href="tel:${phoneNumber}">${phoneNumber}</a></strong>`,
      })
      .then(() => {
        setSuccess(true);
      })
      .catch((e) => {
        console.error(e);
        alert("Ошибка! Что-то пошло не так! Попробуйте отправить снова!");
      })
      .finally(() => {
        setBusy(false);
      });
  };

  return (
    <Modal show={show} centered backdrop={"static"} onHide={handleClose}>
      {!success && (
        <Modal.Header closeButton={!busy}>
          <Modal.Title>Оставьте заявку</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>
        {success ? (
          <div className={"text-center mt-4"}>
            <StaticImage
              style={{ maxWidth: 200 }}
              alt={"Оставить заявку"}
              src={"../assets/call-center-bro.png"}
            />
            <div className={"mb-4"}>
              <h4 className={"text-muted"}>Спасибо что оставили заявку!</h4>
              <h1 className={"text-primary"}>Мы вам перезвоним!</h1>
            </div>
            <Button
              onClick={() => handleClose()}
              variant={"outline-secondary"}
              className={"px-5 rounded-pill"}
            >
              Закрыть
            </Button>
          </div>
        ) : (
          <div className={"position-relative"}>
            <div
              style={{
                transform: "translate(-50%, -50%)",
                left: "50%",
                top: "50%",
              }}
              className={`position-absolute d-${busy ? "block" : "none"}`}
            >
              <Spinner animation="border" variant="primary" />
            </div>
            <div style={{ opacity: busy ? ".1" : "1" }}>
              <p className={"text-muted font-roboto"}>
                Получите подробную консультацию. Мы подберём для вас подходищий
                курс программирования
              </p>
              <div className={"d-grid gap-2"}>
                <Form.Control
                  type="text"
                  value={fullName}
                  disabled={busy}
                  onChange={(e) => setFullName(e.target.value)}
                  placeholder="Ваше имя"
                />
                <Form.Control
                  type="number"
                  value={phoneNumber}
                  disabled={busy}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Ваш телефон номер *"
                />
                <Button
                  variant="primary"
                  disabled={phoneNumber.length === 0 || busy}
                  onClick={() => submit()}
                >
                  Перезвонить мне!
                </Button>
                <small className={"text-muted font-roboto"}>
                  Оставляя заявку, Вы даете согласие на обработку персональных
                  данных.
                </small>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default CallbackComponent;
