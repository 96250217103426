import React from 'react'
import {useState, useEffect} from 'react'
import {Col, Row} from 'react-bootstrap'
import ReactTextTransition, {presets} from 'react-text-transition'

const texts = ['творим волшебство', 'проектируем', 'развиваться']

function JoinUsComponent() {
  const [textIndex, setTextIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex((p) => p + 1)
    }, 4000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <div className="container p-4 p-md-0">
      <div className="join-us p-4 p-md-5">
        <div className="position-relative">
          <h1 className="title d-block justify-content-center d-md-flex gap-2">
            <div>Давай</div>
            <div>
              <ReactTextTransition
                springConfig={presets.gentle}
                style={{margin: '0 4px'}}
                inline
              >{texts[textIndex % texts.length]}</ReactTextTransition>{' '}
            </div>
            <div>вместе!</div>
          </h1>
          <Row>
            <Col>
              <div
                style={{
                  textShadow: '0 0.125rem 0.25rem rgba(0,0,0,.075)',
                }}
              >
                Besoft Group — молодая динамично развивающаяся компания,
                заинтересованная в привлечении в свои ряды инициативных,
                энергичных, целеустремленных и творческих профессионалов,
                способных работать в команде, готовых к совершенствованию
                профессиональных знаний и опыта.
              </div>
              <div
                className="mt-2"
                style={{
                  textShadow: '0 0.125rem 0.25rem rgba(0,0,0,.075)',
                }}
              >
                Мы всегда рады приветствовать тех, кто готов стать членом
                команды профессионалов нашей Компании.
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default JoinUsComponent
