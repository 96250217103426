import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faWhatsapp, faInstagram} from '@fortawesome/free-brands-svg-icons'
import {faPhone, faLocationDot} from '@fortawesome/free-solid-svg-icons'

function FooterComponent() {
  return (
    <footer className="p-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-4 align-self-center">
            <h1>
              <a href={'https://besoft.kg'} rel={'noreferrer'} target={'_blank'} className={'text-primary'}>Besoft</a>
              {' '}
              <a href={'https://teach.besoft.kg'}>Teach</a>
            </h1>
            <p className={'text-muted'}>Сделай первый шаг в мир IT!</p>
            <a href="/office"><i className="fa-solid fa-fw fa-user"/> Личный кабинет</a>
          </div>
          <div className="col-12 col-lg-4">
            <div className="d-flex">
              <div className="icon m-3 me-0">
                <FontAwesomeIcon size={'2xl'} icon={faLocationDot}/>
              </div>
              <div className="p-3">
                <h4>Наш адрес</h4>
                <p className={'text-muted font-roboto'}>город Ош, улица Монуева, 46, второй этаж</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="d-flex">
              <div className="icon m-3 me-0">
                <FontAwesomeIcon size={'2xl'} icon={faPhone}/>
              </div>
              <div className="p-3">
                <h4>Наши контакты</h4>
                <ul className="fa-ul font-roboto">
                  <li>
                    <a href="tel:+996755010727">
                        <span className="fa-li">
                          <FontAwesomeIcon icon={faPhone}/>
                        </span>
                      +996 755 010 727
                    </a>
                  </li>
                  <li>
                    <a href="https://wa.me/996755010727">
                        <span className="fa-li">
                          <FontAwesomeIcon icon={faWhatsapp}/>
                        </span>
                      +996 755 010 727
                    </a>
                  </li>
                  <li>
                    <a href="https://instagram.com/besoft_teach">
                        <span className="fa-li">
                          <FontAwesomeIcon icon={faInstagram}/>
                        </span>
                      besoft_teach
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default FooterComponent
