import React from 'react'
import {Col, Row} from 'react-bootstrap'

function WhoAreWeComponent() {
  return (
    <div className="bg-light who-are-we p-5 text-center">
      <h1 className="title">Кто мы?</h1>
      <Row>
        <Col>
          <div className="lead text-muted">
            Академия программирования Besoft Teach занимается подготовкой
            специалистов в сфере IT с нуля до продвинутого уровня.
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default WhoAreWeComponent
