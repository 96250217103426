import React from 'react'
import {Button} from 'react-bootstrap'
import {useEffect, useState} from 'react'
import {StaticImage} from 'gatsby-plugin-image'

interface HeaderComponentProps {
  setCallbackShow: (value: boolean) => void,
}

function HeaderComponent(props: HeaderComponentProps) {
  const {setCallbackShow} = props
  const [animated, setAnimated] = useState(false)

  useEffect(() => {
    // document.addEventListener('DOMContentLoaded', function () {
      setTimeout(() => {
        setAnimated(true)
      }, 1500)
    // })
  }, [])

  return (
    <div className="header mb-3 container d-flex justify-content-center align-items-center flex-column">
      <StaticImage
        alt={'Besoft Teach'}
        className="mb-2"
        src={'../assets/logo_white_128.png'}
      />
      <h1
        className={`mt-5 fw-lighter main-text`}
        style={{
          ...(animated
            ? {
              opacity: 1,
              transform: 'translateY(0)',
            }
            : {}),
        }}
      >
        Смотрите в будущее с уверенностью — и с навыками для процветания.
      </h1>
      <h3 className="my-5">Besoft Teach - академия программирования</h3>
      <div className={'d-flex justify-content-center'}>
        <Button
          size={'lg'}
          onClick={() => setCallbackShow(true)}
          variant={'outline-light px-5 rounded-pill'}
        >
          Получить консультацию
        </Button>
      </div>
    </div>
  )
}

export default HeaderComponent
