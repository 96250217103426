import React from 'react'
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus, faMobileScreenButton} from '@fortawesome/free-solid-svg-icons'
import {
  faPython,
  faHtml5,
  faReact,
  faAndroid,
  faAppStore,
  faCss3Alt,
  faYarn,
  faNode,
  faNpm,
  faJsSquare,
  faGitlab,
  faMdb,
  faGithub,
  faFontAwesome,
  faBootstrap,
  faGitAlt,
  faLinux,
  faCloudflare,
  faDocker,
  faDigitalOcean,
} from '@fortawesome/free-brands-svg-icons'

const COURSES = [
  {
    title: 'ReactJS',
    description: 'Frontend разработка',
    details: [
      <div className="card-text font-roboto">
        <div><span className={'text-muted'}>Длительность:</span> 5 месяцев</div>
        <div><span className={'text-muted'}>График:</span> 5 дней в неделю</div>
        <div><span className={'text-muted'}>Сложность:</span> 3/10</div>
        <div className={'my-2'}>
          <div><FontAwesomeIcon icon={faPlus}/> Английский по субботам</div>
        </div>
        <div>
          <div className={'text-muted'} style={{fontWeight: 500}}>Технологии</div>
          {[
            {icon: faReact, title: 'ReactJS'},
            {icon: faHtml5, title: 'HTML5'},
            {icon: faCss3Alt, title: 'CSS3'},
            {icon: faGitAlt, title: 'Git'},
            {icon: faBootstrap, title: 'Bootstrap'},
            {icon: faFontAwesome, title: 'FontAwesome'},
            {icon: faGithub, title: 'GitHub'},
            {icon: faMdb, title: 'MDB'},
            {icon: faGitlab, title: 'GitLab'},
            {icon: faJsSquare, title: 'JavaScript'},
            {icon: faNpm, title: 'NPM'},
            {icon: faNode, title: 'NodeJS'},
            {icon: faYarn, title: 'Yarn'},
          ].map(g => (
            <OverlayTrigger
              placement="top"
              overlay={(props) => (
                <Tooltip {...props}>{g.title}</Tooltip>
              )}
            >
              <span className={'tech-item p-1 d-inline-block'}>
                <FontAwesomeIcon icon={g.icon} size={'2x'} />
              </span>
            </OverlayTrigger>
          ))}
        </div>
      </div>,
    ],
    icon: <FontAwesomeIcon size={'2xl'} icon={faReact}/>,
  },
  {
    title: 'React Native',
    description: 'Mobile разработка',
    details: [
      <div className="card-text font-roboto">
        <div><span className={'text-muted'}>Длительность:</span> 5 месяцев</div>
        <div><span className={'text-muted'}>График:</span> 5 дней в неделю</div>
        <div><span className={'text-muted'}>Сложность:</span> 5/10</div>
        <div className={'my-2'}>
          <div><FontAwesomeIcon icon={faPlus}/> Английский по субботам</div>
        </div>
        <div>
          <div className={'text-muted'} style={{fontWeight: 500}}>Технологии</div>
          {[
            {icon: faReact, title: 'ReactJS'},
            {icon: faGitAlt, title: 'Git'},
            {icon: faGithub, title: 'GitHub'},
            {icon: faAndroid, title: 'Android'},
            {icon: faAppStore, title: 'AppStore'},
            {icon: faGitlab, title: 'GitLab'},
            {icon: faJsSquare, title: 'JavaScript'},
            {icon: faNpm, title: 'NPM'},
            {icon: faNode, title: 'NodeJS'},
            {icon: faYarn, title: 'Yarn'},
          ].map(g => (
            <OverlayTrigger
              placement="top"
              overlay={(props) => (
                <Tooltip {...props}>{g.title}</Tooltip>
              )}
            >
              <span className={'tech-item p-1 d-inline-block'}>
                <FontAwesomeIcon icon={g.icon} size={'2x'} />
              </span>
            </OverlayTrigger>
          ))}
        </div>
      </div>,
    ],
    icon: <FontAwesomeIcon size={'2xl'} icon={faMobileScreenButton}/>,
  },
  {
    title: 'Python',
    description: 'Backend разработка',
    details: [
      <div className="card-text font-roboto">
        <div><span className={'text-muted'}>Длительность:</span> 5 месяцев</div>
        <div><span className={'text-muted'}>График:</span> 5 дней в неделю</div>
        <div><span className={'text-muted'}>Сложность:</span> 8/10</div>
        <div className={'my-2'}>
          <div><FontAwesomeIcon icon={faPlus}/> Английский по субботам</div>
        </div>
        <div>
          <div className={'text-muted'} style={{fontWeight: 500}}>Технологии</div>
          {[
            {icon: faPython, title: 'Python'},
            {icon: faGitAlt, title: 'Git'},
            {icon: faGithub, title: 'GitHub'},
            {icon: faDigitalOcean, title: 'DigitalOcean'},
            {icon: faDocker, title: 'Docker'},
            {icon: faCloudflare, title: 'CloudFlare'},
            {icon: faGitlab, title: 'GitLab'},
            {icon: faLinux, title: 'Linux'},

          ].map(g => (
            <OverlayTrigger
              placement="top"
              overlay={(props) => (
                <Tooltip {...props}>{g.title}</Tooltip>
              )}
            >
              <span className={'tech-item p-1 d-inline-block'}>
                <FontAwesomeIcon icon={g.icon} size={'2x'}/>
              </span>
            </OverlayTrigger>
          ))}
        </div>
      </div>,
    ],
    icon: <FontAwesomeIcon size={'2xl'} icon={faPython}/>,
  },
]

interface CoursesComponentProps {
  setCallbackShow: (v: boolean) => void
}

function CoursesComponent(props: CoursesComponentProps) {
  const {setCallbackShow} = props

  return (
    <>
      <h1 className="text-center mt-5 mb-3">Наши курсы</h1>
      <p className={'my-3 text-center text-muted fs-6'}>Выбери себе подходящий курс и стань программистом в
        следующих направлениях</p>
      <div className="row justify-content-center courses">
        {COURSES.map(g => (
          <div className="col-12 col-md-6 col-lg-4">
            <div className="card h-100">
              <div className="card-body d-flex justify-content-between flex-column">
                <div>
                  <div className={'d-flex mb-1 justify-content-start align-items-center'}>
                    <div className={'me-2 p-2 icon'}>{g.icon}</div>
                    <div>
                      <h5 className="card-title text-primary">{g.title}</h5>
                      <h6 className="card-subtitle mb-2 text-muted">{g.description}</h6>
                    </div>
                  </div>
                  {g.details}
                </div>
                <div className="d-grid mt-2">
                  <Button variant={'outline-primary'} onClick={() => setCallbackShow(true)}>Записаться</Button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default CoursesComponent
